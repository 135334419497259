//////////////////////////////////////////
// Code for Guestbook operation
//////////////////////////////////////////

import axios from 'axios';

const form = document.getElementById('inputForm');
//const eMail = document.getElementById('email');

const comment = document.getElementById('comment');
const guestBook = document.getElementById('guestBook');
const closeBtn = document.getElementById('gbClose');

form.addEventListener('submit', (event) => {
  //console.log('clicked guestbook submit');

  // Get the text from the guestbook form
  const comment = form.elements['comment'];
  //console.log('comment=', comment.value);

  // Need to get name of open trip
  const openItem = document.getElementsByClassName('item open');
  const destination = openItem[0].getElementsByClassName('destination');
  //console.log('text=', destination[0].innerText);

  // Prepare to database guestbook.  NOTE: activePhoto fullName, email and notify are no longer used
  const guestbookEntry = {
    comment: comment.value,
    activeTrip: destination[0].innerText,
    activePhoto: 'none specific',
    fullName: 'N/A',
    email: 'N/A',
    notify: false,
  };
  //console.log('guestbookEntry=', guestbookEntry);

  createTripGuestbook(guestbookEntry);

  // Hide the guestbook
  guestBook.style.display = 'none';

  event.preventDefault();
});

//Intercep any enter keys
const formElement = document.getElementById('inputForm');
formElement.addEventListener('keypress', (event) => {
  if (event.key === 'Enter') {
    // key code of the keybord key
    //console.log('inputForm=', event.key);
    event.preventDefault();
    // your code to Run
  }
});

// Clear comment box
comment.addEventListener('click', (event) => {
  console.log('clicked comment');
  comment.textContent = '';
});

closeBtn.addEventListener('click', (event) => {
  //console.log('clicked close button');
  guestBook.style.display = 'none';
});

// Save update note in Guestbook database
async function createTripGuestbook(guestbookNew) {
  //console.log('guestbookNew=', guestbookNew);

  await axios({
    method: 'post',
    //url: 'http://127.0.0.1:3000/api/v1/guestbooks',
    url: 'https://safe-journey-35449.herokuapp.com/api/v1/guestbooks',

    data: {
      guestbookNew,
    },
  });
}
